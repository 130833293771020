<template>
  <!-- DESTOP -->
  <nav
    v-if="!this.$store.state.data.isMobile"
    class="sticky flex justify-center w-screen h-20 bg-atol-blue shadow-2xl z-10"
  >
    <div
      class="flex wrapper justify-between text-white font-gotham antialiased font-normal"
    >
      <button type="button" @click="redirectToHome">
        <img
          class="my-auto h-10 max-h-10 ml-7"
          :src="require(`@/assets/logo-horizontal.svg`)"
          alt="Atol Logo"
        />
      </button>
      <div class="my-auto w-5/12 mr-18">
        <div class="w-full">
          <label for="search" class="sr-only">Rechercher un magasin</label>
          <div class="relative">
            <div
              class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center"
            >
              <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              id="search"
              autocomplete="false"
              v-on:keyup="filterSearch"
              v-model="outputValueOnEnter"
              class="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm text-gray-500 placeholder-gray-500 noborder sm:text-sm"
              placeholder="Rechercher un magasin"
              type="search"
            />
          </div>
        </div>
        <div
          class="w-search h-max-96 z-20 p-2 mx-auto absolute bg-white rounded-lg shadow text-atol-blue-electric overflow-y-auto max-h-420"
          v-if="result.length && outputValueOnEnter"
          v-click-outside="onClickOutside"
        >
          <div v-for="res in result" :key="res.code_ato">
            <button
              type="button"
              @click="redirectToDash(res.code_ato)"
              class="block hover:bg-gray-50 w-full"
            >
              <div class="px-4 py-4 sm:px-6">
                <div class="flex items-center justify-between">
                  <p
                    class="text-sm uppercase font-bold text-atol-blue-electric truncate"
                  >
                    {{ res.name }}
                  </p>
                  <div
                    class="flex items-center justify-between bg-atol-blue-light rounded-full px-3"
                  >
                    <div
                      class="bg-atol-blue-medium rounded-full h-2 w-2 mr-2"
                    ></div>
                    <div class="font-book text-sm text-atol-blue-electric">
                      {{ res.code_ato }}
                    </div>
                  </div>
                </div>
                <div class="mt-2 sm:flex sm:justify-between">
                  <div class="sm:flex text-left">
                    <p
                      class="flex items-center text-sm text-gray-500 uppercase text-left"
                    >
                      {{ res.address }} - {{ res.city }}
                    </p>
                  </div>
                  <div
                    v-if="res.cpmr"
                    class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 uppercase"
                  >
                    <p>{{ res.cpmr.name }} {{ res.cpmr.first_name }}</p>
                  </div>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div class="flex">
        <div
          v-if="this.$store.state.users.user.first_name"
          class="my-auto mr-2 text-sm"
        >
          Bonjour, {{ this.$store.state.users.user.first_name }}
          {{ this.$store.state.users.user.last_name }}
        </div>
        <router-link
          :to="{ path: '/profile' }"
          class="flex items-center justify-center my-auto mr-4 h-8 w-8 rounded-full bg-atol-purple uppercase"
        >
          <p v-if="this.$store.state.users.user.first_name" class="p-1">
            {{ this.$store.state.users.user.first_name[0]
            }}{{ this.$store.state.users.user.last_name[0] }}
          </p>
        </router-link>
      </div>
      <div class="my-auto mr-6">
        <button
          type="button"
          class="underline font-gotham font-black uppercase text-base"
          @click="handleDeconnexion"
        >
          Déconnexion
        </button>
      </div>
      <div
        class="my-auto mr-6"
        v-if="this.$store.state.users.user.is_admin"
      >
        <button
          type="button"
          class="underline font-gotham font-black uppercase text-base"
          @click="redirectToAdmin"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
            />
          </svg>
        </button>
      </div>
    </div>
  </nav>
  <!-- MOBILE -->
  <nav
    v-else
    class="sticky w-full flex justify-center w-screen bg-atol-blue shadow-2xl z-10 pt-2 px-4 pb-4 sm:px-10 md:px-10 lg:px-20"
  >
    <div
      class="flex w-full flex-col justify-between text-white font-gotham antialiased font-normal"
    >
      <div class="flex flex-wrap items-start justify-between">
        <button type="button" @click="redirectToHome">
          <img
            class="m-2 h-10 sm:h-16"
            :src="require(`@/assets/logo-horizontal.svg`)"
            alt="Atol Logo"
          />
        </button>
        <div class="flex flex-col pt-2 pb-1">
          <div class="flex items-center justify-end">
            <button
              type="button"
              class="underline font-gotham font-black uppercase text-base mr-4"
              @click="redirectToAdmin"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-7 w-7"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                />
              </svg>
            </button>
            <button
              type="button"
              class="underline font-gotham font-black uppercase text-base mr-2"
              @click="handleDeconnexion"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-7 w-7"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                />
              </svg>
            </button>
          </div>
          <div class="flex pt-3 justify-end">
            <div
              v-if="this.$store.state.users.user.first_name"
              class="my-auto mr-2 text-sm hide"
            >
              Bonjour, {{ this.$store.state.users.user.first_name }}
              {{ this.$store.state.users.user.last_name }}
            </div>
            <router-link
              :to="{ path: '/profile' }"
              class="flex items-center justify-center my-auto mr-2 h-8 w-8 rounded-full bg-atol-purple uppercase"
            >
              <p v-if="this.$store.state.users.user.first_name" class="p-1">
                {{
                  getFirstLetters(
                    this.$store.state.users.user.first_name[0],
                    this.$store.state.users.user.last_name[0]
                  )
                }}
              </p>
            </router-link>
          </div>
        </div>
      </div>
      <div class="my-auto w-full mx-auto mt-2">
        <div class="w-full">
          <label for="search" class="sr-only">Rechercher un magasin</label>
          <div class="relative">
            <div
              class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center"
            >
              <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              id="search"
              autocomplete="false"
              v-on:keyup="filterSearch"
              v-model="outputValueOnEnter"
              class="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm text-gray-500 placeholder-gray-500 noborder sm:text-sm"
              placeholder="Rechercher un magasin"
              type="search"
            />
          </div>
        </div>
        <div
          class="w-search h-max-96 z-20 p-2 mx-auto absolute bg-white rounded-lg shadow text-atol-blue-electric overflow-y-auto max-h-420 custom-width"
          v-if="result.length && outputValueOnEnter"
          v-click-outside="onClickOutside"
        >
          <div v-for="res in result" :key="res.code_ato">
            <button
              type="button"
              @click="redirectToDash(res.code_ato)"
              class="block hover:bg-gray-50 w-full"
            >
              <div class="px-4 py-4 sm:px-6">
                <div class="flex items-center justify-between">
                  <p
                    class="text-sm uppercase font-bold text-atol-blue-electric truncate"
                  >
                    {{ res.name }}
                  </p>
                  <div
                    class="flex items-center justify-between bg-atol-blue-light rounded-full px-3"
                  >
                    <div
                      class="bg-atol-blue-medium rounded-full h-2 w-2 mr-2"
                    ></div>
                    <div class="font-book text-sm text-atol-blue-electric">
                      {{ res.code_ato }}
                    </div>
                  </div>
                </div>
                <div class="mt-2 sm:flex sm:justify-between">
                  <div class="sm:flex text-left">
                    <p
                      class="flex items-center text-sm text-gray-500 uppercase text-left"
                    >
                      {{ res.address }} - {{ res.city }}
                    </p>
                  </div>
                  <div
                    v-if="res.cpmr"
                    class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 uppercase"
                  >
                    <p>{{ res.cpmr.name }} {{ res.cpmr.first_name }}</p>
                  </div>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { SearchIcon } from '@heroicons/vue/solid'
import vClickOutside from 'click-outside-vue3'
import { mapActions } from 'vuex'
import axios from 'axios'

export default {
  name: 'Header',
  el: '#search',
  components: {
    SearchIcon,
  },
  data() {
    return {
      outputValueOnEnter: '',
      result: [],
    }
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    ...mapActions(['logOut', 'getShops', 'unSetShops']),
    submit(e) {
      this.outputValueOnEnter = e.target.value
      e.target.value = ''
    },
    async getShopsData() {
      await this.getShops()
    },
    filterSearch() {
      const lowSearch = this.outputValueOnEnter.toLowerCase()
      const splitted = lowSearch.split(' ')
      let searchList = this.$store.state.shops.shops
      splitted.forEach((element) => {
        searchList = searchList.filter((res) =>
          [
            'name',
            'city',
            'code_ato',
            'address',
            'email',
            'postal_code',
            'cpmr',
            // 'user.first_name', WORKAROUND ARTHURITO
            // 'user.last_name', WORKAROUND ARTHURITO
            // 'cpmr.first_name', WORKAROUND ARTHURITO
            // 'cpmr.last_name', WORKAROUND ARTHURITO
          ].some((key) => {
            const path = key.split('.')
            if (path.length === 1) {
              return String(res[key]).toLowerCase().includes(element)
            } else {
              return String(res[path[0]][path[1]])
                .toLowerCase()
                .includes(element)
            }
          })
        )
      })
      this.result = searchList
    },
    onClickOutside(event) {
      this.outputValueOnEnter = ''
    },
    redirectToAdmin() {
      this.$router.push({ path: '/admin' })
    },
    redirectToHome() {
      this.$router.push({ path: '/' })
    },
    redirectToDash(code_ato) {
      this.outputValueOnEnter = ''
      this.$router.push({ path: `/dashboard/${code_ato}` })
    },
    async handleDeconnexion() {
      await this.logOut()
      await this.$router.push({ path: '/login' })
    },
    getFirstLetters(name, first_name) {
      return first_name[0] + name[0]
    },
  },
  async mounted() {
    this.$store.commit('setIsLoading', true)
    if (this.$store.state.users.is_authenticated) {
      await this.getShopsData()
      this.shops = this.$store.state.shops.shops
    }
    this.$store.commit('setIsLoading', false)
  },
}
</script>

<style>
header#header {
  box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.5);
}

.wrapper {
  width: 1200px;
}
@media only screen and (min-width: 1024px) {
  .w-search {
    width: 500px;
  }
}

.max-h-420 {
  max-height: 420px;
}

@media only screen and (max-width: 400px) {
  .hide {
    display: none;
  }
}
</style>

<style scoped>
@media only screen and (max-width: 640px) {
  .custom-width {
    width: calc(100% - 32px);
  }
}

@media only screen and (min-width: 640px) {
  .custom-width {
    width: calc(100% - 128px);
  }
}

@media only screen and (min-width: 1024px) {
  .custom-width {
    width: calc(100% - 224px);
  }
}
</style>
