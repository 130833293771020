import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index'

const routes = [
  {
    path: '/',
    name: 'Shops',
    component: () => import('../views/Shops')
  },
  {
    path: '/dashboard/:code_ato',
    name: 'Dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/DashView.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/management.vue')
  },
  {
    path: '/pwd-recovery',
    name: 'PwdForget',
    component: () => import('../views/PwdForget.vue')
  },
  {
    path: '/mail-sent',
    name: 'MailSent',
    component: () => import('../views/MailSent')
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('../views/ResetPwd')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/Profile')
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// GOOD
router.beforeEach(async (to, from, next) => {
  if (to.name !== 'Login' && to.name !== 'PwdForget' && to.name !== 'MailSent' && !store.state.users.is_authenticated && to.path !== '/reset-password'){
    console.log('Guard redirect to login')
    next({ name: 'Login' })
  } else if (to.name === 'Admin') {
    await store.dispatch('viewMe')
    if (!store.state.users.user.is_admin) {
      next({ name: 'Shops' })
    } else next()
  } else next()
})

export default router
