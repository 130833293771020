import axios from 'axios'
import { createToast } from 'mosha-vue-toastify'

const state = {
  user: {
    first_name: '',
    name: '',
    is_admin: false
  },
  users: [],
  is_authenticated: false,
  token: '',
  error: []
}

const getters = {
  stateUser: state => state.user,
  stateUsers: state => state.users
}

const actions = {
  // eslint-disable-next-line no-empty-pattern
  async register({ dispatch }, form) {
    await axios.post('users/create/', form)
    await dispatch('getUsers')
  },
  async mailRecoverPwd({}, email) {
    return await axios.post('password-recovery/' + email)
  },
  async changePwd({}, body) {
    await axios.post('reset-password/', body)
  },
  async updatePwd({}, body) {
    await axios.post('update-password/', body)
  },
  async updateAdmin({dispatch}, body) {
    console.log(body)
    await axios.put(`users/${body.id}/admin/${body.admin}`)
    await dispatch('getUsers')
  },
  async logIn({ commit, dispatch }, user) {
    const response = await axios.post('login', user)
    const token = response.data.access_token
    await commit('setToken', token)
    axios.defaults.headers.common.Authorization = 'Bearer ' + token
    await localStorage.setItem('token', token)
    await dispatch('viewMe')
  },
  async viewMe({ commit }) {
    const { data } = await axios.get('users/whoami')
    await commit('setUser', data)
  },
  // eslint-disable-next-line no-empty-pattern
  async deleteUser({ dispatch }, id) {
    await axios.delete(`users/${id}`)
    await dispatch('getUsers')
  },
  async logOut({ commit }) {
    const user = {
      first_name: '',
      name: '',
      is_admin: false
    }
    const token = ''
    axios.defaults.headers.common.Authorization = 'Bearer ' + token
    commit('logout', user)
    commit('removeToken', token)
    commit('resetShops')
    await localStorage.setItem('token', token)
  },
  async 'getUsers'({ commit }) {
    const response = await axios.get('users')
    const users = response.data
    const updated = users.map(function (e) {
      const o = Object.assign({}, e)
      o.open = false
      return o
    })
    await commit('setUsers', updated)
  },
  async 'updateUser'({ dispatch }, user) {

    const id = user.id
    delete user.open
    // delete user.id


    await axios.put(`users/${id}/`, user)
    await dispatch('getUsers')
  }
}

const mutations = {
  'setUser'(state, user) {
    state.user = user
  },
  'setUsers'(state, username) {
    state.users = username
  },
  'logout'(state, user) {
    state.user = user
  },
  async 'initializeStore'(state) {
    if (localStorage.getItem('token')) {
      state.token = localStorage.getItem('token')
      axios.defaults.headers.common.Authorization = 'Bearer ' + state.token
      state.is_authenticated = true
    } else {
      state.token = ''
      state.is_authenticated = false
    }
  },
  'setToken'(state, token) {
    state.token = token
    state.is_authenticated = true
  },
  'removeToken'(state, token) {
    state.token = ''
    state.is_authenticated = false
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
