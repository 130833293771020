import axios from 'axios'

const state = {
  shops: null
}

const getters = {}

const actions = {
  // Header.vue
  async getShop({}, code_ato) {
    const response = await axios.get(`shop/${code_ato}`)
    return response
  },
  async unSetShops({ commit }) {
    await commit('resetShops')
  },
  // AllShops.vue / MyShops.vue
  async getShops({ commit }) {
    const shops = await axios.get('me/shops')
    await commit('setShops', shops.data)
    return shops.data
  },
  // ShopModal.vue
  async addShop(context, shop) {
    const newShop = await axios.post('shops', shop)
  },
  // DownloadModal.vue
  async getShopData(context, payload) {
    const Payload = new FormData()
    Payload.append('year', payload.year)
    Payload.append('month', payload.month)
    return await axios.post(`shops/${payload.code_ato}/data`, Payload)
  },

  // DeleteShop.vue
  async deleteShop({ dispatch }, code_ato) {
    await axios.delete(`shop/${code_ato}`)
  },

  // EDIT MODAL

  async updateShop({ dispatch }, shop) {
    const code_ato = shop.code_ato
    await axios.put(`shop/${code_ato}`, shop)
    await dispatch('getShops')
  }
}

const mutations = {
  setShops(state, shops) {
    state.shops = shops
  },
  resetShops(state) {
    state.shops = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
