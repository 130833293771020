<template>
  <div class="flex flex-col h-screen">
    <Header
      v-if="
        this.$router.currentRoute.value.name !== 'Login' &&
          this.$router.currentRoute.value.name !== 'PwdForget' &&
          this.$router.currentRoute.value.name !== 'MailSent' &&
          this.$router.currentRoute.value.name !== 'reset-password'
      "
    ></Header>

    <div
      class="is-loading-bar text-center"
      :class="{ 'is-loading': this.$store.state.isLoading }"
    >
      <div class="lds-dual-ring"></div>
    </div>

    <div
      class="font-gotham overflow-y-auto flex-1 p-4 sm:px-10 md:px-10 lg:px-20 bg-atol-blue"
      :style="[
        !this.$store.state.data.isMobile
          ? {
              backgroundImage: 'url(' + require(`@/assets/bg.png`) + ')',
              backgroundRepeat: 'no-repeat',
              backgroundAttachment: 'fixed'
            }
          : {}
      ]"
    >
      <router-view />
    </div>
  </div>
</template>

<script>
import Header from './components/Header/Header'
import axios from 'axios'

export default {
  components: { Header },
  async beforeCreate() {
    await this.$store.commit('setIsLoading', true)
    await this.$store.commit('initializeStore')
    if (localStorage.getItem('token')) {
      this.$store.dispatch('viewMe')
    }
    await this.$store.commit('setIsLoading', false)
  },
  methods: {
    // Screen size
    handleResize() {
      window.innerWidth < 1200
        ? (this.$store.state.data.isMobile = true)
        : (this.$store.state.data.isMobile = false)
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  computed: {}
}
</script>

<style lang="css">
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #ccc;
  border-color: #ccc transparent #ccc transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.is-loading-bar {
  overflow: hidden;
  position: absolute;
  height: 0;
  width: 100vw;
  background: rgb(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}
.is-loading {
  height: 100vh;
}
</style>
