import { createApp } from 'vue'
import App from './App'
import router from './router'
import store from './store'
import './index.css'
import vClickOutside from 'click-outside-vue3'
import axios from 'axios'

axios.defaults.withCredentials = true
axios.defaults.baseURL = 'https://atol-analytics-backend.semetis.com/'
// axios.defaults.baseURL = 'http://localhost:8000/'

axios.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token')
    if (token) {
      axios.defaults.headers.common.Authorization = 'Bearer ' + token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
axios.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response)
    } else {
      console.log('failed')
      return Promise.reject(response)
    }
  },
  async (error) => {
    if (error.response.status) {
      switch (error.response.status) {
        case 400:
          //do something
          break

        case 401:
          await store.dispatch('logOut')
          await router.push({ path: '/login' })
          break
        case 403:
          await store.dispatch('logOut')
          await router.push({ path: '/login' })
          break
        case 404:
          break
        case 502:
          setTimeout(async () => {
            await store.dispatch('logOut')
            await router.push({ path: '/login' })
          }, 1000)
      }
      return Promise.reject(error.response)
    }
  }
)

createApp(App).use(store).use(router).use(vClickOutside).mount('#app')
