import axios from 'axios'

const state = {
  date: {
    month: 2,
    year: 2021
  },
  isMobile: false
}

const getters = {}

const actions = {
  // Header.vue

  async uploadData(context, object) {
    return await axios.post('uploads/files', object, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  async uploadShops(context, object) {
    return await axios.post('shop_list', object)
  },
  async providers() {
    return await axios.get('providers')
  },
  async getReport(context, object) {
    return await axios.get(
      `rapports/${object.code_ato}/${object.month}/${object.year}`
    )
  },
  async getFiles() {
    return await axios.get(`uploads/files`)
  }
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
