import Vuex from 'vuex'

import shops from './modules/shops'
import users from './modules/users'
import data from './modules/data.js'

export default new Vuex.Store({
  state: {
    isLoading: false
  },
  mutations: {
    setIsLoading (state, status) {
      state.isLoading = status
    }
  },
  modules: {
    users,
    shops,
    data
  }
})
